@charset "utf-8";
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
ページトップ
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
sect01
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.wrap_sect01{
	margin-bottom:50px;
	.inner_box{
		.inner_left{
			margin-bottom:40px;
			.block_title{
				font-size: 1.8rem;
				margin-bottom: 20px;
			}
			.block_desc{
				font-size:1.4rem;
				margin-bottom:25px;
			}
			.block_link{
				background-color: #1293db;
				border:none;
				color:white;
				display:block;
				font-size:1.5rem;
				height:44px;
				line-height: 44px;
				text-align:center;
				width: 200px;
			}
		}
		.inner_right{

		}
	}
}

@media only screen and (min-width: 767px) {

	.wrap_sect01{
		margin-bottom:60px;
		.inner_box{
			display: flex;
			justify-content: space-between;
			.inner_left{
				margin-bottom:0px;
				width:calc(74% - 50px);
				.block_title{
					font-size: 2rem;
				}
				.block_desc{
					font-size:1.5rem;
					margin-bottom:35px;
				}
				.block_link{

				}
			}
			.inner_right{
				width:26%;
			}
		}
	}
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
sect02
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.wrap_sect02{
	.inner_box{
		.inner_col{
			background-color:#eeeeee;
			padding: 30px;
			.block_title{
				font-size: 1.8rem;
				margin-bottom: 20px;
				text-align: center;
			}
			.block_desc{
				font-size:1.4rem;
				margin-bottom:20px;
			}
			.block_img{
				margin-bottom:40px;
			}
			.block_link{
				background-color:white;
				border:solid 1px #1293db;
				color:#1293db;
				display:block;
				font-size:1.5rem;
				height:44px;
				line-height: 44px;
				margin:0 auto;
				text-align:center;
				width: 200px;
			}
			&+.inner_col{
				margin-top:40px;
			}
		}
		.inner_right{

		}
	}
}

@media only screen and (min-width: 767px) {

	.wrap_sect02{
		.inner_box{
			display: flex;
			justify-content: space-between;
			.inner_col{
				padding: 40px;
				width:calc(50% - 30px);
				.block_title{
					font-size: 2rem;
				}
				.block_desc{
					font-size:1.5rem;
					margin-bottom:30px;
				}
				.block_img{
					margin:0 auto 40px;
					max-width: 250px;
				}
				.block_link{

				}
				&+.inner_col{
					margin-top:0px;
				}
			}
			.inner_right{

			}
		}
	}

}

