@charset "UTF-8";
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
ページトップ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
sect01
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap_sect01 {
  margin-bottom: 50px; }
  .wrap_sect01 .inner_box .inner_left {
    margin-bottom: 40px; }
    .wrap_sect01 .inner_box .inner_left .block_title {
      font-size: 1.8rem;
      margin-bottom: 20px; }
    .wrap_sect01 .inner_box .inner_left .block_desc {
      font-size: 1.4rem;
      margin-bottom: 25px; }
    .wrap_sect01 .inner_box .inner_left .block_link {
      background-color: #1293db;
      border: none;
      color: white;
      display: block;
      font-size: 1.5rem;
      height: 44px;
      line-height: 44px;
      text-align: center;
      width: 200px; }

@media only screen and (min-width: 767px) {
  .wrap_sect01 {
    margin-bottom: 60px; }
    .wrap_sect01 .inner_box {
      display: flex;
      justify-content: space-between; }
      .wrap_sect01 .inner_box .inner_left {
        margin-bottom: 0px;
        width: calc(74% - 50px); }
        .wrap_sect01 .inner_box .inner_left .block_title {
          font-size: 2rem; }
        .wrap_sect01 .inner_box .inner_left .block_desc {
          font-size: 1.5rem;
          margin-bottom: 35px; }
      .wrap_sect01 .inner_box .inner_right {
        width: 26%; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
sect02
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap_sect02 .inner_box .inner_col {
  background-color: #eeeeee;
  padding: 30px; }
  .wrap_sect02 .inner_box .inner_col .block_title {
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: center; }
  .wrap_sect02 .inner_box .inner_col .block_desc {
    font-size: 1.4rem;
    margin-bottom: 20px; }
  .wrap_sect02 .inner_box .inner_col .block_img {
    margin-bottom: 40px; }
  .wrap_sect02 .inner_box .inner_col .block_link {
    background-color: white;
    border: solid 1px #1293db;
    color: #1293db;
    display: block;
    font-size: 1.5rem;
    height: 44px;
    line-height: 44px;
    margin: 0 auto;
    text-align: center;
    width: 200px; }
  .wrap_sect02 .inner_box .inner_col + .inner_col {
    margin-top: 40px; }

@media only screen and (min-width: 767px) {
  .wrap_sect02 .inner_box {
    display: flex;
    justify-content: space-between; }
    .wrap_sect02 .inner_box .inner_col {
      padding: 40px;
      width: calc(50% - 30px); }
      .wrap_sect02 .inner_box .inner_col .block_title {
        font-size: 2rem; }
      .wrap_sect02 .inner_box .inner_col .block_desc {
        font-size: 1.5rem;
        margin-bottom: 30px; }
      .wrap_sect02 .inner_box .inner_col .block_img {
        margin: 0 auto 40px;
        max-width: 250px; }
      .wrap_sect02 .inner_box .inner_col + .inner_col {
        margin-top: 0px; } }
